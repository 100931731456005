const menus = [
  {
    route: '#',
    label: 'Program',
    subMenus: [
      {
        route: '/activity',
        label: 'Aktivitas',
      },
    ],
  },
  {
    route: '#',
    label: 'Laporan Keuangan',
    subMenus: [
      {
        route: '/income',
        label: 'Pemasukan',
      },
    ],
  },
]

export const settingsMenu = [
  {
    route: '#',
    label: 'Pengaturan',
    subMenus: [
      {
        route: '/settings/appearance',
        label: 'Pengaturan Tampilan',
      },
      {
        route: '/settings/role',
        label: 'Pengaturan Role',
      },
      {
        route: '/settings/user',
        label: 'Pengaturan User',
      },
    ],
  },
]

export default menus
