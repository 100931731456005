import { useRoutes } from 'react-router-dom'

import incomeRoutes from 'income/routes'
import activityRoutes from 'activity/routes'
import commonRoutes from 'commons/routes.js'
import userRoutes from 'user/routes'
import roleRoutes from 'role/routes'

const GlobalRoutes = () => {
  const router = useRoutes([
    ...incomeRoutes,
    ...activityRoutes,
    ...commonRoutes,
    ...userRoutes,
    ...roleRoutes,
  ])

  return router
}

export default GlobalRoutes
